/** @jsx jsx */
import { jsx } from 'theme-ui';
import Member from '../Common/Member';

const Staff = () => {
  const members = [
    {
      name: 'Dr. Jeffrey Keenan',
      position: 'NEDC President',
      description: `<a href="https://www.youtube.com/watch?v=49YoE6I51rE" >Dr. Jeffrey Keenan</a>, a highly respected infertility and reproductive medicine specialist, leads the NEDC team. He is the center’s president and medical director. He is board certified in Obstetrics and Gynecology, as well as Reproductive Endocrinology and Infertility.<br/><br/>In practice since 1990, Dr. Keenan has performed innumerable fertility procedures with outstanding success rates. <a href="https://www.youtube.com/watch?v=49YoE6I51rE">To get to know Dr. Keenan better through an in-depth conversation with master interviewer Hallerin Hilton Hill, click here.</a>`,
      image: '/images/staff/staffJeff_edit-150x150.png',
    },
    {
      name: 'Mark Mellinger',
      position: 'Marketing and Development Director',
      description:
        'Mark Mellinger handles all marketing and public relations functions for the NEDC and is also in charge of our development efforts. His background consists of almost three decades in broadcast journalism. From 2011 to 2018, Mark served as a contributor to <a href="https://www.thegospelcoalition.org/">The Gospel Coalition</a>, one of the world’s largest Christian websites, where he <a href="https://www.thegospelcoalition.org/profile/mark-mellinger/">helped start TGC’s podcast operations and regularly interviewed some of the world’s most influential evangelical teachers and leaders.</a><br/>You may email Mark at mmellinger@baby4me.net.',
      image: '/images/staff/Mark-Mellinger-150x150.jpeg',
    },
    {
      name: 'Janice Lamontagne, RN',
      position: 'Nurse Manager',
      description:
        'Janice oversees the office operations and assists NEDC Staff with coordination of care. Janice has 30 years of nursing experience as a Clinical Educator and Director of Nursing in Tennessee and California.',
      image: '/images/staff/Janice.jpg',
    },
    {
      name: 'Christy Smith, RN',
      position: 'Patient Coordinator',
      description:
        'Christy Smith is a Knoxville native. She has wanted to help people since she was very young. She has been in the medical field for the past 26 years.',
      image: '/images/staff/ChristySmith.jpg',
    },
    {
      name: 'Megan McCloud, RN',
      position: 'Donor Nurse Coordinator',
      description: 'Megan McCloud is a Registered Nurse who helps guide donors through the NEDC process. She has previous experience as a pediatric and public health nurse.',
      image: '/images/staff/Megan-McCloud.png',
    }
  ];
  return (
    <section
      sx={{
        bg: '#61A19E',
        backgroundImage: `url('/images/background2.png')`,
      }}
    >
      <div
        className="content"
        sx={{
          px: '2rem',
          py: '3rem',
        }}
      >
        <div>
          <h1
            sx={{
              variant: 'text.xl',
              color: '#fff',
              textAlign: 'center',
            }}
          >
            Our Staff
          </h1>
        </div>
        <div sx={{ display: 'flex', flexWrap: 'wrap', gap: '2rem', my: '3rem' }}>
          {members.map((member, index) => (
            <Member key={index} data={{ ...member, index }} mode="dark" />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Staff;
